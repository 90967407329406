html {
  --blue-800: #35414c;
  --blue-900: #18232d;

  --white-0: #fff;
  --white-100: #e6e6e6;
  --white-200: #ccc;
  --white-300: #999999;
  background-color: #18232d;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  letter-spacing: -0.025em;
  color: var(--white-200);
}
schedulebody {
  display: flex;
  flex-direction: column;
  background-color: #18232d;
}
dark {
  display: flex;
  flex-direction: column;
  background-color: #18232d;
  min-height: 100vh;
  height: 100%
}

.testing_box {
  font-size: 62.5%;
  font-family: "Poppins", sans-serif;
  line-height: 1;
  font-weight: 400;
  background-color: #18232d;
}


.shell {
  font-size: 62.5%;
  font-family: "Poppins", sans-serif;
  line-height: 1;
  font-weight: 400;
  background-color: #18232d;

}



/******************/
/* NAV */
/******************/

.nav-container {
  height: 8rem;
  background-color: #35414c;
  padding: 0.4rem 2.4rem 0.4rem 1.2rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-logo {
  height: 5rem;
  margin-right: -3rem;
  object-fit: contain;
}

.nav-title-container {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}

.nav-text-container {
  display: flex;
  gap: 0.6rem;
  white-space: nowrap;
}

.nav-bottom-container {
  display: flex;
  align-items: center;
  gap: 4.2rem;
}

.nav-credits-container {
  font-size: 1.25rem;
}

#main-nav-button {
  width: 3.8rem;
  height: 3.8rem;
  object-fit: contain;
  background: none;
  cursor: pointer;

  display: none;
}

.nav-title-container p {
  font-size: 1.8rem;
  color: var(--white-0);
}

.nav-bottom-container p {
  font-size: 1.6rem;
  color: var(--white-100);
  cursor: pointer;
}

.nav-bottom-container p:hover {
  text-decoration: underline;
}

/******************/
/* MAIN */
/******************/
main {
  margin: 0 auto;
  width: 100vw;
  max-width: 130rem;
  padding-bottom: 10.8rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;

}

#main {
  /* height: calc(100vh - 8rem); */
  display: grid;
  column-gap: 4.8rem;
  grid-template-columns: 6fr 4fr;

}

#main-left {
  padding: 3.6rem 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.main-left-title-container {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  margin-bottom: 4.8rem;

  align-self: flex-start;
}

.main-left-title {
  font-size: 3.2rem;
  line-height: 3.2rem;
  color: var(--white-0);
  font-weight: 400;
}

.main-left-subtitle {
  font-size: 1.4rem;
}

.main-left-periodDetail {
  display: flex;
  align-items: center;
  gap: 1.6rem;
  margin-bottom: 3.6rem;
}

.main-left-periodDetail p {
  font-size: 1.6rem;
  /* visibility: hidden; */
}

.main-left-timeDetail-container {
  display: flex;
  gap: 14rem;
}

.main-left-time-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-left-time-title {
  font-size: 1.2rem;
}

.main-left-time {
  font-size: 8.2rem;
  color: var(--white-0);
  line-height: 8.7rem;
}

.main-bottom-time {
  font-size: 8.2rem;
  color: var(--white-0);
  line-height: 8.7rem;
}

.counter-container {
  margin-top: -2rem;
}

.countdown_font {
  font-size: 3.8rem;
  max-width: 450px;
  color: var(--white-0);
  line-height: 4.5rem;
}

/******************/
/* RIGHT SIDE */
/******************/
#main-right {
  padding: 2.4rem 0;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  overflow-y: auto;
}

.main-right-period-container {
  padding: 2rem 2.4rem;
  margin-right: 0.4rem;
  border: 2px solid var(--blue-800);
  border-radius: 0.8rem;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-right-period-container>div {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.main-right-period-container p {
  color: #727a82;
}

.main-right-period-title {
  font-size: 2.4rem;
  line-height: 3rem;
}

.main-right-period-time-container {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin-top: 0.2rem;
  color: #aaa;
}

.main-right-duration {
  font-size: 1.6rem;
}

/* current period */
.main-right-period-container-CURRENT {
  background-color: var(--blue-800);
  border: solid #9aa0a6 2px;
  padding: 3rem 3.2rem;
  opacity: 1;
}

.main-right-period-container-CURRENT div {
  color: #fff;
}

.main-right-period-container-CURRENT .main-right-period-title {
  font-size: 3.2rem;
  margin-top: 0.9rem;
  font-weight: 500;
  position: relative;
}

.main-right-period-container-CURRENT .main-right-period-title::before {
  content: "CURRENT PERIOD";
  color: #ffe066;
  letter-spacing: 0.8px;
  font-size: 0.9rem;
  font-weight: 400;
  position: absolute;
  top: -22.5px;
}

.main-right-period-container-CURRENT .main-right-period-time-container {
  font-size: 1.8rem;
  padding-top: 0.4rem;
  color: #ccc;
}

.main-right-period-container-CURRENT .main-right-duration {
  font-size: 1.8rem;
}

/* anchor time */
.main-right-period-container-ANCHOR {
  position: relative;
}

.main-right-period-container-ANCHOR::after {
  content: "Anchor Time";
  border: solid 1px #727a82;
  padding: 0.8rem 1.6rem;
  border-radius: 4rem;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(0, -50%);
  font-size: 1.4rem;
  z-index: 999;
}

/******************/
/* FOOTER */
/******************/
/* settings / customization */
footer {
  display: flex;
  flex-direction: column;
  gap: 10rem;
}

.footer-title {
  font-size: 3.6rem;
  line-height: 3.4rem;
  font-weight: 500;
  margin-bottom: 0.2rem;
}

.footer-subtitle {
  font-size: 1.4rem;
  color: #aaa;
  font-weight: 200;
  margin-bottom: 4.2rem;
}

.footer-settings-section-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 4.8rem;
  padding: 0 3.6rem;
}

.vertical-div-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footer-settings-container {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  padding-bottom: 1.8rem;
}

.footer-settings-icon {
  height: 30% !important;
  padding: 1.2rem;
  border-radius: 50%;
}

#footer-settings-icon-lunch {
  background-color: #82c91e72;
}

#footer-settings-icon-minimal {
  background-color: #7950f29a;
}

.verticalLine {
  border-left: 3px solid var(--blue-800);
  border-radius: 8px;
}

.footer-settings-title {
  font-size: 1.6rem;
}

.footer-lunch-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 1.2rem;
  padding: 0 3.6rem;

}

.footer-lunch {
  padding: 1.2rem 2.4rem;
  height: 9.6rem;
  border: solid 1px var(--blue-800);
  border-radius: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
}

.footer-lunch:hover {
  box-shadow: 0 0 10px rgba(255, 255, 255, 20%);
}

.footer-lunch p {
  color: #9aa0a6;
}

.footer-lunch input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.footer-lunch-period {
  font-size: 2.8rem;
  line-height: 3rem;
}

.footer-lunch-detail-container {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  justify-self: start;
}

.footer-lunch-detail {
  padding-left: 0.8rem;
  font-size: 1.4rem;
  line-height: 1.2rem;
  font-weight: 300;
  color: #aaa;
}

/* selected  */

.footer-lunch-selected .footer-lunch {
  background-color: var(--blue-800);
  border: solid #9aa0a6 2px;
}

.footer-lunch-selected p,
.footer-lunch-selected .footer-lunch-detail {
  color: #fff;
}

/* credits */
.footer-credit-section-container {
  padding: 0 3.6rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 2.8rem;
  column-gap: 3.6rem;
}

.footer-credit-section {
  border-radius: 10px;
  height: 135px;
  display: flex;
  justify-content: flex-start;
  gap: 1.2rem;
}

.footer-credit-section div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.footer-credit-icon {
  height: 50% !important;
  padding: 0.1rem;
  align-self: center;
}

.footer-credit-title {
  font-size: 3.6rem;
  line-height: 4.1rem;
  font-weight: 500;
  margin-top: -0.2rem;
}

.footer-credit-detail div {
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 200;
  color: #9aa0a6;
}

/* resources */
.footer-resources-container {
  padding: 0 4.2rem;
  padding-top: 3.6rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem;
}

.footer-resource {
  background-color: #303b44;
  border-radius: 10px;
  aspect-ratio: 1 / 1;
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.footer-resource:hover {
  box-shadow: 0 0 6px rgba(255, 255, 255, 20%);
  transform: translateY(-1rem);
}

.footer-resource-icon {
  box-sizing: border-box;
  margin-bottom: 2.4rem;
  height: 25% !important;
}

.footer-resource-title {
  font-size: 1.4rem;
  font-weight: 600;
}

/******************/
/* CLOCK https://codepen.io/Akshita-Saxena/pen/vYPmQqy */
/******************/

.container {
  position: relative;
  margin-top: 3.4rem;
  margin-bottom: 2rem;
}

.clock {
  width: 25rem;
  height: 25rem;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.25);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.clock span {
  position: absolute;
  transform: rotate(calc(30deg * var(--i)));
  inset: 12px;
  text-align: center;
}

.clock span b {
  transform: rotate(calc(-30deg * var(--i)));
  display: inline-block;
  font-size: 1.6rem;
}

.clock::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fff;
  z-index: 2;
}

.hand {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.hand i {
  position: absolute;
  background-color: var(--clr);
  width: 4px;
  height: var(--h);
  border-radius: 8px;
}

#sec i {
  width: 2px;
}

/******************/
/* custom scroll bar */
/******************/

#main-right::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0);
}

#main-right::-webkit-scrollbar {
  width: 6px;
  background-color: rgba(0, 0, 0, 0);
}

#main-right::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.7);
  background-color: #555;
}


/******************/
/* MODAL */
/******************/

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: var(--blue-800);
  border: solid #fbe605 1px;
  padding: 3rem 3.2rem;
  /* padding: 2rem; */
  border-radius: 10px;
  z-index: 1000;
  width: 100%;
  max-width: 450px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal h2 {
  font-size: 2.5rem;
  line-height: 2.5rem;
  color: var(--white-0);
  font-weight: 400;
  margin-bottom: 2rem;
  text-align: center;
}

.modal p {
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 300;
  margin-bottom: 2rem;
  text-align: center;
}

.modal span {
  font-size: 1.75rem;
  line-height: 1.75rem;
  font-weight: 300;
  color: var(--white-0);
}

.modal-close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  font-size: 2rem !important;
  font-weight: 600 !important;
  cursor: pointer;
}

.modal-divider {
  display: flex;
  width: 100%;
  border-top: solid rgba(251, 230, 5, 0.8) 1px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  gap: 1rem;
}



.footer-home {
  padding: 1.2rem 2.4rem;
  height: 40.6rem;
  border: solid 1px var(--blue-800);
  border-radius: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
}

.footer-home-period {
  font-size: 2.8rem;
  line-height: 3rem;
}

.footer-home-detail {
  font-size: 2.4rem;
  line-height: 1.2rem;
  font-weight: 300;
  color: #aaa;
}

.footer-home-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 1.2rem;
  padding: 2.6rem;

}

.home-div {
  display: grid;
  grid-template-areas:
    'Schedule Schedule Planner  Planner'
    'Schedule Schedule Planner  Planner'
    'Schedule Schedule Planner  Planner'
    'info info info info';
}

.img-schedule {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


@media (max-width: 500px) {
  
.home-div {
  display: grid;
  grid-template-areas:
    'Schedule Schedule'
    'Planner Planner'
    'info info info info';
}
}

.Schedule {
  grid-area: Schedule;
}
.Planner {
  grid-area: Planner;
}
.info {
  grid-area: info;
  padding: 1.2rem;
}

.footer-home:hover {
  box-shadow: 0 0 10px rgba(255, 255, 255, 20%);
}

.footer-home p {
  color: #9aa0a6;
}


