

/*****************************************/
/* HEADERS */
/*****************************************/
header {
  padding: 0.6rem 2.4rem;
  border-bottom: solid 1px #d9d9d9;

  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
  background-color: #18232d;
}

.header-logo-container {
  grid-column: span 2;

  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.header-logo {
  height: 6rem;
  width: 6rem;
}

.header-title {
  font-size: 1.4rem;
  font-weight: 500;
}

.header-navs {
  grid-column: 3 / 5;
  width: 100%;

  display: flex;
}

.header-nav {
  list-style: none;
  flex-grow: 1;
  flex-basis: 0;
  text-align: center;
}

.header-nav:first-child {
  margin-left: 4.8rem;
}

.header-nav-link {
  color: #333333;
  font-size: 1.6rem;
  font-weight: 500;

  text-decoration: none;
}

.header-nav-link:hover {
  color: #003698;
  border-bottom: solid 1.5px#003698;
}

/*****************************************/
/* Core */
/*****************************************/
core {
  
  min-height: 100vh;
  height: 100%;
  display: grid;
  grid-template-columns: 7fr 3fr;
  height: 100%;
  background-color: #18232d;
}

#courses {
  padding: 0 1.6rem;
  margin: 1.0rem;
}

.courses-title-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 2.8rem 0;
}

.courses-title {
  font-size: 4.2rem;
  font-weight: 600;
  padding-bottom: 0.4rem;
}

.course-subtitle {
  font-size: 1.4rem;
  padding-left: 0.4rem;
}

.courses-filter-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-bottom: 1.8rem;
}

.courses-filter:first-child {
  padding-left: 0;
}

.courses-filter {
  display: flex;
  flex-direction: column;
  padding: 0 0.8rem;
}

.courses-filter-title {
  font-size: 1.4rem;
  padding-bottom: 0.4rem;
}

.courses-keyinput {
  height: 3.2rem;
}

.courses-dropdown {
  height: 3.2rem;
}

.courses-course-container {
  overflow: auto;
  text-align: justify;
  height: 60rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.6rem;
}

.courses-course {
  padding: 0.8rem 0.8rem;
  border: solid 1.5px #d9d9d9;
  border-radius: 8px;
}

.courses-course-img {
  width: 100%;
  height: 16rem;
  border-radius: 8px;
  margin-bottom: 1.2rem;
  object-fit: cover;
}

.courses-course-title-container {
  padding-bottom: 0.8rem;
}

.courses-course-title {
  font-size: 2.2rem;
  font-weight: 600;
  letter-spacing: -0.4px;
  cursor: pointer;
}

.courses-course-title:hover {
  border-bottom: solid 2px #333333;
}

.courses-course-detail-container {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  padding-bottom: 2.6rem;
}

.courses-course-detail {
  display: flex;
  align-items: center;
  gap: 1.4rem;
}

.courses-course-detail-icon {
  font-size: 2.2rem;
}

.courses-course-detail-p {
  font-size: 1.2rem;
}

.courses-course-detail-addtional-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.courses-course-detail-addtional {
  color: #003698;
  font-weight: 600;
  font-size: 1.2rem;
  background-color: rgba(77, 114, 183, 0.2);
  padding: 0.8rem 1.6rem;
}

.courses-course-detail-add {
  font-size: 2.8rem;
  border-radius: 50%;
  background-color: #003698;
  color: #fff;
  cursor: pointer;
}

/*****************************************/
/* SIDEBAR */
/*****************************************/
#sidebar {
  border-radius: 30px;
  padding: 0 1.6rem;
  background-color: #35414c;
  height: 100%;
  position: relative;
}

.sidebar-title-container {
  background-color: #35414c;
  margin: 2.4rem 0;
}

.sidebar-title {
  
  font-size: 2.2rem;
  margin-bottom: 0.4rem;
}

.sidebar-subtitle {
  
  font-size: 1.4rem;
}

.sidebar-course-container {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.sidebar-course {
  
  background-color: #8dc6fb3a;
  border: solid 1px #555;
  padding: 1.2rem 1.4rem;
  display: grid;
  grid-template-columns: 4fr 5fr 2fr;
  column-gap: 1.2rem;
  border-radius: 8px;
}

.sidebar-course-img {
  
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.sidebar-course-detail-container {
  
  margin: auto 0;
}

.sidebar-course-tag {
  
  font-size: 1.4rem;
}

.sidebar-course-title {
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 0.8rem;
}

.sidebar-course-credit {
  
  font-size: 1.4rem;
}

.sidebar-course-icon-container {
  
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sidebar-course-icon {
  font-size: 2.4rem;
  color: #f03e3e;
  border-radius: 50%;
  border: solid 2px #f03e3e;
}

.sidebar-calc-container {
  
  width: 100%;
  background-color: #35414c;
  padding: 1.8rem 1.2rem;
  border-radius: 8px 8px 0 0;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

  position: absolute;
  bottom: 0;
  left: 0;
}

.sidebar-calc-title-container {
  
  background-color: #35414c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.2rem;
}

.sidebar-calc-title {
  
  font-size: 2.4rem;
  font-weight: 600;
}

.sidebar-clac-filter {
  
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.sidebar-clac-filter-title {
  
  font-size: 1.2rem;
}

.sidebar-calc-calc-container {
  
  background-color: #35414c;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1.2rem;
}

.sidebar-calc-calc-title {
  font-size: 1.6rem;
}

.sidebar-calc-calc-number {
  font-size: 1.8rem;
  font-weight: 500;
  justify-self: end;
}

/*****************************************/
/* BOTTOM */
/*****************************************/
