html {
    font-size: 62.5%;
}

/* 1300px or less */
@media (max-width: 81em) {
    main {
        max-width: 120rem;
    }
}

/* 1200px or less */
@media (max-width: 75em) {
    html {
        /* 9px / 16px  */
        font-size: 56.25%;
      }

    main {
        max-width: 110rem;
    }

    .modal, .modal-overlay {
        display: flex;
    }
}

/* 1000px or less */
@media (max-width: 62.5em) {
    html {
        /* 8px / 16px = 0.5 = 50% */
        font-size: 50%;
    }

    main {
        max-width: 100rem;
        display: flex;
        flex-direction: column;
    }

    .main-left-title-container {
        margin-bottom: 0;
    }
}

/* 850px or less */ 
@media (max-width: 53em) {
    main {
        max-width: 80rem;
    }

    .counter-container {
        margin-top: 2rem;
    }

    .counter-container div {
        width: 300px !important;
        height: 300px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .countdown_font {
        font-size: 2.75rem;
    }

    #main {
        grid-template-columns: auto;
    }

    #main-nav-button {
        display: block;
    }

    .nav-bottom-container p {
        display: none;
    }

    .footer-lunch-container {
        padding: 0;
    }

    .footer-settings-section-container {
        grid-template-columns: auto;
        gap: 4rem;
    }

    .footer-credit-section-container {
        grid-template-columns: auto;
    }

    .footer-resources-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        flex-wrap: wrap;
        gap: 1.2rem;
    }
}

/* 680px or less */
@media (max-width: 42.5em) {
    main {
        max-width: 65rem;
    }

    .nav-container {
        flex-direction: column;
        padding-bottom: 2rem;
    }

    .nav-credits-container {
        margin-top: -0.75rem;
    }

    .main-left-timeDetail-container {
        gap: 6rem;
    }

    .footer-title, .footer-subtitle {
        padding-left: 0.5rem;
    }

/* 
    #main-right {
        display: flex;
        flex-direction: row;
    } */
}

/* 530px or less */ 
@media (max-width: 33em) {
    main {
        max-width: 50rem;
        padding: 0 1rem 4rem 1rem
    }

    .modal {
        top: auto;
        left: 50%;
        bottom: 20px;
        transform: translateX(-50%);
    }
}